import React, { Component } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import * as actions from '../../../store/actions'
import OverviewPanel from '../../../Components/Panels/Overview'
import Rating from '../../../Components/Rating'
import WorkLayout from '../../../layouts/work'
import PageHeader from '../../../Components/PageHeaders/Secondary'
import MasterDeck from '../../../decks/publix_tax_case-study_master.pdf'
import PublixAboutImage from '../../../images/publix-about.png'
import PublixPIMSGif from '../../../images/publix-pims-animation.gif'
import PublixPIMSImage from '../../../images/publix-pims-1.jpeg'
import PublixProcessImage from '../../../images/publix-process.jpg'
import PublixTaxWorkshopImage from '../../../images/publix-workshop-1.jpg'
import PublixTaxEventStormImage from '../../../images/publix-event-storm.png'
import PublixTaxInterviewMVPImage from '../../../images/publix-tax-interview-mvp.png'
import PublixTFEStore from '../../../images/publix-tfe-store.jpg'
import LinkWithIcon from '../../../Components/Links/IconLabel'
import ColorBlock from '../../../Components/ContentBlock/ColorBlock'
import FrameBlock from '../../../Components/ContentBlock/FrameBlock'
import InfoHighlight from '../../../Components/Callouts/InfoHighlight'

function mapStateToProps(state) {
  return { page: state.pageList.productPage };
}

const mapDispatchToProps = (dispatch) => {
  const {addFeedback} = actions;
  return {
    addFeedback: bindActionCreators(addFeedback, dispatch)
  }
}

class PublixTax extends Component {
  constructor() {
    super();

    this.state = {
      rating: 0
    }
  }

  render() {
    let fullLink = `/password-gate?redirect=${MasterDeck}`

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 992,
          settings: 'unslick'
        }
      ]
    };

    return (
      <WorkLayout header="secondary" className="publix">
        <PageHeader
            title="Modernizing Publix's mainframe systems into a new, delightful experience"
            time="2022 - 2023 • Product Strategy | Facilitator | Acting BSA | Lead Designer"
            featuredImage={ PublixAboutImage }
            fullLink={fullLink}
            >
            <OverviewPanel>
              <h3>I led a large-scale, multi-year effort to redesign and modernize many of Publix's outdated internal systems.</h3>
              <p>Overcoming growing pains and process gaps, we began the project by creating a more efficient, scalable, and user-friendly tax system that now manages 300k+ items. Through our lean design approach, we were able to reduce the task completion time by 98%, improve auditing and save Publix money on errors and storage cost.</p>
            </OverviewPanel>
        </PageHeader>
        <Container>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{ md: 2 }}>
              <header className="work-header">
                <h2>Publix has 1300+ locations in 8 states</h2>
              </header>
              <p>Publix has been recognized by <a href="https://www.theledger.com/story/business/2022/02/02/publix-ranks-no-1-among-grocers-customer-service-and-charity/9314040002/" target="_blank" rel="noopener noreferrer">Forbes as the #1 grocery chain</a>, employing over 240,000 people, generating over 48 billion in annual sales, and expanding operations across the Southeastern region of the United States.</p>
            </Col>
          </Row>
        </Container>

        <ColorBlock className="work__block work__block--color publix-mainframe" color="black" isCenter isHero>
          <Container>
            <Row>
              <Col md={8} push={{md: 2}}>
                  <h2>Bidding farewell to &#x27;90s mainframe</h2>
                  <p>With the imminent retirement of the developer, the only one who really knows this system inside out, Publix is set to invest significant resources into a new, state-of-the-art system designed to streamline business processes.</p>

              </Col>
            </Row>
          </Container>
        </ColorBlock>

        <ColorBlock className="work__block work__block--color" title="Top 3 Challenges I Faced" color="grey" isCenter isHero>
          <Slider className="work__slider" {...settings}>
            <FrameBlock>
              <header className="work-header">
                <h2>Challenge #1: Waterfall -> Agile</h2>
                <h3>Navigating a transition from waterfall to agile methodologies can be quite the adventure, especially at a large company who is deeply rooted in a culture of waterfall delivery and bound by a roadmap full of prior commitments.</h3>
              </header>
              <ul>
                <li><b>Current Process:</b> Waterfall</li>
                <li><b>Design Maturity:</b> Beginning stages</li>
                <li><b>Project State:</b> Previously created System Requirement Document (SRD).</li>
                <li><b>Teams:</b> Split into 5 newly formed agile teams</li>
                <li><b>My Assignment:</b> Discover new solutions through implementing user centered design processes</li>
              </ul>
            </FrameBlock>
            <FrameBlock>
              <header className="work-header">
                <h2>Challenge #2: Breaking past biases</h2>
                <h3>With an average tenure of 25+ years, it was challenging to break past existing solutions and build better products than previously thought. Afterall, the team spent 18 months creating a requirement document before my arrival.</h3>
              </header>
              <ul>
                <li><b>Prioritize learning over growth:</b> Make the right thing first - then scale.</li>
                <li><b>Shared ownership:</b> Include the team every step of the discovery process.</li>
                <li><b>Continuous discovery:</b> Weekly engagement with business and users.</li>
                <li><b>Outcomes > Output:</b> Showcasing the value we've delivered to stakeholders.</li>
              </ul>
            </FrameBlock>
            <FrameBlock>
              <header className="work-header">
                <h2>Challenge #3: Where to Start?</h2>
                <h3>Given a behemoth of a system with only one team formed thus far, we needed to understand the business processes and identify which current systems were involved to decide on a feasible starting point.</h3>
              </header>
              <img className="work__image" src={PublixTaxEventStormImage} alt=""/>
            </FrameBlock>
          </Slider>
        </ColorBlock>

        <ColorBlock className="work__block work__block--color" title="Goal 1: Discovering our starting point" color="blackSoft" isCenter isHero>
          <Container className="work__content">
            <Row>
              <Col className="work__column" md={6} push={{md: 0}}>
                <header>
                  <h5>SOLUTION</h5>
                  <h2>Creating a Centralized Queue:</h2>
                  <h3>Choose an item in the queue, determine tax plan, and apply taxes as usual.</h3>
                </header>
                <p>In a focused Zoom session with our team and users, we uncovered that requests were being managed through email. By creating a single, efficient platform for handling all tax requests, we could not only offer immediate value to users but also provide a practical starting point for developers to begin configuring their environments.</p>
              </Col>
              <Col className="work__column" md={5} push={{md: 1}}>
                <img className="work__image--decor" src={ PublixTaxInterviewMVPImage } alt="Discovery UX Process Diagram"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="work__results">
                  <li><b>80%↑</b>task time efficiency</li>
                  <li><b>10k+</b>new items per year</li>
                  <li><b>40%↓</b>steps in process flow</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </ColorBlock>

        <ColorBlock className="work__block work__block--color" title="Goal 2: Eliminate current system" color="grey" swigglyColor="purple" isCenter isHero>
          <Container className="work__content">
            <Row>
              <Col className="work__column" md={5} push={{md: 0}}>
                <img className="work__image--decor" src={ PublixTaxWorkshopImage } alt="Design Workshop"/>
              </Col>
              <Col className="work__column" md={6} push={{md: 1}}>
                <header>
                  <h5>SOLUTION</h5>
                  <h2>A new way to tax items</h2>
                  <h3>The next step addressed the limitations of the existing item grouping system, which, while efficient for Buyers, hindered accurate taxation. The solution involved a separate, tax-oriented grouping system.</h3>
                </header>
                <p>Faced with the inefficiencies of the current item grouping system, we organized focused workshops to explore better options. These efforts led to the development of a separate grouping system tailored for tax purposes. This new system not only improves tax accuracy but also maintains the efficiency needed by Buyers, paving the way for a more streamlined taxation process and moving beyond the shortcomings of the old approach.</p>
                <ul className="work__results">
                  <li><b>98%↑</b>task time efficiency</li>
                  <li><b>~80%↓</b>paper waste</li>
                  <li><b>100%↓</b>tax errors</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </ColorBlock>

        <ColorBlock className="work__block work__block--color" title="Goal 3: Tax-Free Events" color="white" swigglyColor="purple" isCenter isHero>
          <Container className="work__content">
            <Row>
              <Col className="work__column" md={6} push={{md: 0}}>
                <header>
                  <h5>SOLUTION</h5>
                  <h2>Empower Tax Users</h2>
                  <h3>Goal 3 tackled the financial costs arising from inaccurate tax collection during tax-free events by empowering users with the ability to create highly tailored tax-free scenarios.</h3>
                </header>
                <p>By introducing a system that enables users to set up customized tax-free scenarios, we provided them with the tools to manage these events more accurately and efficiently. This enhancement not only reduced financial discrepancies but also streamlined the process, giving users greater control and confidence in handling tax-free situations.</p>
                <ul className="work__results">
                  <li><b>1</b>system of reference</li>
                  <li><b>60%↓</b>steps in process flow</li>
                  <li><b>80%↓</b>paper waste</li>
                </ul>
              </Col>
              <Col className="work__column" md={5} push={{md: 1}}>
                <img className="work__image--decor" src={ PublixTFEStore } alt="Design Workshop"/>
              </Col>
            </Row>
          </Container>
        </ColorBlock>

        <Container>
          <Row className="work__block">
            <Col className="work__column" md={8} push={{md: 2}}>
              <header className="work-header work__callout">
                <h2>Takeaways</h2>
                <LinkWithIcon className="work__link" to={ fullLink } icon="lock">See Full Case Study</LinkWithIcon>
              </header>
              <p>I'm proud to say we drastically improved our users daily workflow by significantly streamlining the process, eliminating errors, and most importantly showcased the value discovery research brings to the business.</p>
            </Col>
          </Row>
          <Row className="work__block work__conclusion">
            <Col xs={8} push={{xs: 2}}>
              <Rating {...this.props} />
            </Col>
          </Row>
        </Container>
      </WorkLayout>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps)(PublixTax)
);
