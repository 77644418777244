import React, { Component } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { NavLink, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import DefaultLayout from '../../layouts/default'
import Icon from '../../icons'
import CaseStudyPrimary from '../../Components/Panels/CaseStudy/Primary'
import WalmartPanelImage from '../../images/walmart-display.png'
import PublixPanelImage from '../../images/publix-approval.png'
import DrewGif from '../../images/drew-gif.gif'
import PublixBgImage from '../../images/publix-pattern_savings.png'
import TropicanaBgImage from '../../images/pattern-dot-gradient.png'
import TropicanaDeck from '../../decks/tropicana_case-study_master.pdf'
import TropicanaPanelImage from '../../images/tropicana-display.png'

class Home extends Component {
  constructor() {
    super();

    this.state = {
      timeOfDay: ''
    }
  }

  componentDidMount() {
    const today = new Date()
    const hour = today.getHours()

    let experience = this.calculateExperience(2013)
    let timeOfDay

    if (hour < 12) {
      timeOfDay = "morning"
    } else if (hour < 17) {
      timeOfDay = "afternoon"
    } else {
      timeOfDay = "evening"
    }

    this.setState({ timeOfDay, experience })
  }

  calculateExperience(startDate) {
    var ageDifMs = Date.now();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - startDate);
  }

  render() {
    const { timeOfDay, experience } = this.state

    return (
      <DefaultLayout header="secondary" className={classnames(timeOfDay)}>
        <div className="home">
          <Container>
            <Row>
              <Col md={10} push={{md: 1}}>
                <section className={classnames(timeOfDay, "home-hero")}>
                  <div className="home-hero__content">
                    <img className="home-hero__image" src={DrewGif} alt="me being me"/>
                    <h1 className="home-hero__text">
                      G&apos; {timeOfDay}, <NavLink to="bio">I&apos;m Drew</NavLink>. I&apos;m a Designer @ Publix - {experience} years experience with an emphasis on lean methodology - Facinated by AI, AR/VR - Mentored 30+ students @ CareerFoundry - Impacted 100M+ users at companies such as Walmart, It Works!, and Tropicana.
                    </h1>
                  </div>
                  <div className="home-hero-hint">
                    <Icon className="home-hero-hint__icon" name="carat-down" />
                  </div>
                </section>
              </Col>
            </Row>
            <Row>
              <Col md={10} push={{md: 1}} className="case-studies__showcase">
                <CaseStudyPrimary
                    title="Revolutionizing how Publix manages tax on items."
                    text="Find out how we discovered a new taxing experience, shaving 98% off task times and saving substantial costs, while streamlining the taxing process for thousands of items annually."
                    image={PublixPanelImage}
                    link="/work/publix-tax"
                    logo="publix"
                    color="green"
                    year="2022-2023"
                    backgroundImage={PublixBgImage}
                    />
                <CaseStudyPrimary
                    title="From kiosk design to comprehensive problem solving."
                    text="Learn how I uncovered costly business problems and found a new, cost effective solution that resulted in saving $225k per year and increased driver income."
                    image={TropicanaPanelImage}
                    link={TropicanaDeck}
                    deck="true"
                    logo="tropicana"
                    color="yellow"
                    year="2021"
                    backgroundImage={TropicanaBgImage}
                    />
                <CaseStudyPrimary
                    title="A new out-of-stock experience at Walmart."
                    text="Have you ever visited Walmart's website or app only to find that the item you want is out of stock? Our team worked on improving the user experience for backordered items by implementing a new notification system."
                    image={WalmartPanelImage}
                    link="/work/walmart-backorder"
                    logo="walmart"
                    color="blue"
                    year="2021"
                    />
              </Col>
            </Row>
            <Row className="other-work">
              <Col>
                <header className="other-work__header">
                  <h2>Browse other projects</h2>
                </header>
                <nav className="other-work__nav">
                  <ul>
                    <li><NavLink to="/work/nextech-design-system">2021 – Nextech Design System</NavLink></li>
                    <li><NavLink to="/work/connect">2015-2020 – It Works! Connect iOS + Android App</NavLink></li>
                    <li><NavLink to="/work/product-page">2019 – It Works! Product Page Redesign</NavLink></li>
                    <li><NavLink to="/work/other/palz-tap-house">Bonus Project: Palz Tap House Branding</NavLink></li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
      </DefaultLayout>
    )
  }
}

export default withRouter(Home);
